@media (orientation: landscape) {
  .single-line-header {
    font-size: 3vw;
  }

  .subheader-line {
    font-size: 2.5vw;
    line-height: 3.5vw;
  }
}

@media (orientation: portrait) {
  .single-line-header {
    font-size: 3vh;
  }

  .subheader-line {
    font-size: 2.5vh;
    line-height: 3.5vh;
  }
}
