@media (orientation: landscape) {
  .single-line-hero {
    font-size: 4.5vw;
  }
  .single-line-world {
    font-size: 3.4vw;
  }
  .single-line-cta {
    font-size: 3.4vw;
  }
}

@media (orientation: portrait) {
  .single-line-hero {
    font-size: 4.5vh;
  }
  .single-line-world {
    font-size: 3.4vh;
  }
  .single-line-cta {
    font-size: 3.4vh;
  }
}
