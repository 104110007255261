.email-signup-form {
  display: flex;
  flex-direction: column; /* Arrange elements in a column */
  justify-content: center;
  align-items: center;
  gap: 10px; /* Spacing between input and button */
}

.email-input,
.signup-button {
  width: 70%; /* Control the width of input and button */
  max-width: 400px; /* Maximum width */
  padding: 10px;
  border-width: 1px;
  border-style: solid;
  /* border: 2px solid text-primary; Blue border */
  border-radius: 5px; /* Rounded corners */
  font-size: 1rem;
  outline: none; /* Removes the default focus outline */
}

/* .email-input:focus {
  border-color: black; /* Darker border on focus */
/* } */
.signup-button {
  /* background-color: black; Blue background */
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.signup-button:hover {
  background-color: black; /* Darker blue on hover */
}

input::placeholder {
  @apply text-primary;
}

.toast-message {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #48bb78; /* green color, you can change as needed */
  color: white;
  padding: 10px 20px;
  border-radius: 4px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}
