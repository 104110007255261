.timeline {
  position: relative;
  max-width: 1200px; /* Restrict the width on large screens */
  margin: 0 auto; /* Center the tl-content */
  padding: 30px;
}
.timeline::after {
  content: "";
  position: absolute;
  width: 6px;
  background-color: white;
  top: 0;
  bottom: 0;
  left: 50%;
  margin-left: -3px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  z-index: 1;
}
.tl-container {
  position: relative;
  width: 50%;
  min-height: 120px;
}
.tl-container-left {
  left: 0;
  padding-right: 70px;
  text-align: right;
}
.tl-container-right {
  left: 50%;
  padding-left: 70px;
  text-align: left;
}
.tl-content {
  padding: 30px;
  background-color: white;
  position: relative;
  border-radius: 6px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
}
.tl-container .tl-content::before {
  content: " ";
  height: 0;
  position: absolute;
  top: 35px;
  width: 0;
  z-index: 1;
  border: medium solid white;
}
.tl-container-left .tl-content::before {
  right: -10px;
  border-width: 10px 0 10px 10px;
  border-color: transparent transparent transparent white;
}
.tl-container-right .tl-content::before {
  left: -10px;
  border-width: 10px 10px 10px 0;
  border-color: transparent white transparent transparent;
}
.tl-image {
  position: absolute;
  width: 90px;
  height: 90px;
  background-size: cover;
  background-position: center;
  border: solid 5px #ffffff;
  border-radius: 50px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  z-index: 2;
}
.tl-container-left .tl-image {
  right: 0;
  margin-right: -45px;
}
.tl-container-right .tl-image {
  left: 0;
  margin-left: -45px;
}
.tl-content span {
  color: #2c7a7b;
  font-size: 1.1em;
  font-weight: bold;
}
.tl-content h2 {
  font-size: 1.8em;
  padding-top: 5px;
}
.tl-content p {
  line-height: 1.6;
  padding-top: 5px;
}
@media screen and (max-width: 767px) {
  .timeline::after {
    left: 65px;
  }
  .tl-container {
    width: 100%;
    padding-left: 100px;
    padding-right: 0px;
    margin-top: 30px;
  }
  .tl-container:first-of-type {
    margin-top: 0px;
  }
  .tl-container-right {
    left: 0%;
  }
  .tl-container-left {
    text-align: left;
  }
  .tl-container .tl-image {
    left: -10px;
    top: 0px;
    margin-left: 0;
    margin-right: 0;
  }
  .tl-container-left .tl-content::before {
    left: -10px;
    right: auto;
    border-width: 10px 10px 10px 0;
    border-color: transparent white transparent transparent;
  }
}
@media screen and (max-width: 480px) {
  .tl-container {
    padding-left: 0px;
    padding-top: 105px;
    z-index: 3;
  }
  .tl-container .tl-content::before {
    left: 25px;
    top: -10px;
    border: medium solid white;
    border-width: 0 10px 10px 10px;
    border-color: transparent transparent white transparent;
  }
}
