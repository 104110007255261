@media (orientation: landscape) {
  .LandingPageHeaderLandscape__more {
    font-size: 8vw !important;
  }
}

@media (orientation: portrait) {
  .LandingPageHeaderLandscape__more {
    font-size: 8vh !important;
  }
}
